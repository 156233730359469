





import axios from 'axios'
import storage from '@/store';
import { getToken } from "@/utils/auth.js"
import message from "@/plugins/modal.js"

axios.defaults.headers['Content-Type'] = 'application/json;charset=utf-8'
const service = axios.create({
  baseURL: 'https://m996u58725.zicp.fun',
  timeout: 50000
})


function blobValidate(data) {
  return data.type !== 'application/json'
}






service.interceptors.request.use(
  (config) => {
    
    const isRepeatSubmit = (config.headers || {}).repeatSubmit === false

    
    if (['get', 'put', 'delete'].includes(config.method) && config.params) {
      
      const encodedParams = new URLSearchParams(config.params);
      
      config.params = encodedParams;
    }

    const token = getToken();
    config.headers['Authorization'] = "Bearer " + token;  

    if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
      const requestObj = {
        url: config.url,
        data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
        time: new Date().getTime()
      }
      const requestSize = Object.keys(JSON.stringify(requestObj)).length; 
      const limitSize = 5 * 1024 * 1024; 
      if (requestSize >= limitSize) {
        return console.warn(`[${config.url}]: ` + '请求数据大小超出允许的5M限制，无法进行防重复提交验证。')
      }
    }
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);




service.interceptors.response.use(function (response) {
  let res = response.data;
  
  if (!["application/json", "application/json;charset=utf-8"].includes(response.headers["content-type"]) && blobValidate(response)) {
    return response
  }
  if (+res.code == 401) {
    return message.alertConfirme("提示", "会话状态已过期，您可以继续留在该页面，或重新登录").then(() => {
      storage.dispatch('Logout').then(() => location.href = '/index')
    })
  }
  return res;
}, function (error) {
  console.log("err = ", error);
  
  if (error.response.status == 500) {
    return message.msgError("服务器更新维中,请稍后尝试...")
  }
  
  return Promise.reject(error);
});

export default service;
